import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Urls } from '@vezeeta/web-utils';
import { SPECIALTIES } from '../actions/specialties';

function* getMainSpecialties(action) {
  const mainSpecialtiesApi = new API();
  const mainSpecialtiesApiResponse = yield mainSpecialtiesApi.get(Urls.getMainSpecialities, [
    {
      key: 'language',
      value: `en-${action.culture}`
    }
  ]);

  if (STATUS.isSuccess(mainSpecialtiesApiResponse.status)) {
    yield put({
      type: SPECIALTIES.MAIN_SPECIALTIES.SUCCEEDED,
      mainSpecialties: mainSpecialtiesApiResponse.data
    });
  } else {
    yield put({
      type: SPECIALTIES.MAIN_SPECIALTIES.FAILED,
      error: mainSpecialtiesApiResponse.data
    });
  }
}

function* getSubSpecialties(action) {
  const subSpecialtiesApi = new API();
  const subSpecialtiesApiResponse = yield subSpecialtiesApi.get(
    `${Urls.getSubSpecialities}?specialityKey=${action.mainSpecialty}`
  );

  if (STATUS.isSuccess(subSpecialtiesApiResponse.status)) {
    yield put({
      type: SPECIALTIES.SUB_SPECIALTIES.SUCCEEDED,
      subSpecialties: subSpecialtiesApiResponse.data
    });
  } else {
    yield put({
      type: SPECIALTIES.SUB_SPECIALTIES.FAILED,
      error: subSpecialtiesApiResponse.data
    });
  }
}

function* specialtiesSaga() {
  yield takeEvery(SPECIALTIES.MAIN_SPECIALTIES.LOADING, getMainSpecialties);
  yield takeEvery(SPECIALTIES.SUB_SPECIALTIES.LOADING, getSubSpecialties);
}

export default specialtiesSaga;
