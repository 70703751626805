const CoustomUrls = {
  signIn: `${process.env.REACT_APP_OFFERS_URL}/portal/login`,
  getAllUnits: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetAllUnits`,
  getUnitDetails: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetUnitDetails`,
  getUnitsPerCountry: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetUnitsPerCountry`,
  addEditUnit: `${process.env.REACT_APP_OFFERS_URL}/Attribute/AddUnit`,
  getAllServices: `${process.env.REACT_APP_OFFERS_URL}/portal/GetAllServicesByLevel`,
  getServiceDetails: `${process.env.REACT_APP_OFFERS_URL}/portal/GetServiceDetails`,
  addService: `${process.env.REACT_APP_OFFERS_URL}/portal/AddServiceAcrossCountries`,
  updateService: `${process.env.REACT_APP_OFFERS_URL}/portal/updateService`,
  getOffers:`http://voffers-2-prelive.drbridge.org/api/portal/GetOffers`
};
export default CoustomUrls;
