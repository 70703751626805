import styled from 'styled-components';

export const EnvelopContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 8px;
`;

export const UserName = styled.p`
  color: #fff;
  font-size: 14px;
  line-height: 27px;
`;

export const OptionListContainer = styled.div`
  min-height: 30px;
  padding: 8px 0px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: absolute;
  min-width: 160px;
  top: 35px;
  direction: ltr;
  z-index: 10;
  right: -10px;
`;

export const Item = styled.div`
  width: 100%;
  padding: 4px 12px;
  display: flex;
  direction: ${props => props.theme.direction};
  &:hover {
    background: rgb(219, 232, 246);
  }
`;

export const OptionLabel = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0070cd !important;
  margin: 0 10px;
`;

export const OptionIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: auto 0px;
`;

export const DownCarret = styled.img`
  width: 8px;
  height: 8px;
  margin: auto 0px;
`;