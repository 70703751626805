export const UNITS = {
  FETCH: {
    LOAD: 'UNITS_FETCH',
    SUCCESS: 'UNITS_FETCH_SUCCESS',
    FAIL: 'UNITS_FETCH_FAIL'
  },
  FETCH_DETAILS: {
    LOAD: 'UNITS_FETCH_DETAILS',
    SUCCESS: 'UNITS_FETCH_DETAILS_SUCCESS',
    FAIL: 'UNITS_FETCH_DETAILS_FAIL'
  },
  ADD_EDIT: {
    LOAD: 'UNITS_ADD_EDIT',
    SUCCESS: 'UNITS_ADD_EDIT_SUCCESS',
    FAIL: 'UNITS_ADD_EDIT_FAIL'
  },
  SNACK_BAR: {
    HIDE: 'UNITS_SNACK_BAR_HIDE'
  },
  MODAL: {
    TOGGLE_DELETE: 'DELETE_MODAL'
  }
};

export const fetchUnits = (pageIndex, pageSize) => ({
  type: UNITS.FETCH.LOAD,
  pageIndex,
  pageSize
});

export const fetchUnitsSuccess = units => ({
  type: UNITS.FETCH.SUCCESS,
  units
});

export const fetchUnitsFail = () => ({
  type: UNITS.FETCH.FAIL
});

export const fetchUnitDetails = unitKey => ({
  type: UNITS.FETCH_DETAILS.LOAD,
  unitKey
});

export const fetchUnitDetailsSuccess = unitDetails => ({
  type: UNITS.FETCH_DETAILS.SUCCESS,
  unitDetails
});

export const fetchUnitDetailsFail = () => ({
  type: UNITS.FETCH_DETAILS.FAIL
});

export const addEditUnit = unitObj => ({
  type: UNITS.ADD_EDIT.LOAD,
  unitObj
});

export const addEditUnitSuccess = units => ({
  type: UNITS.ADD_EDIT.SUCCESS,
  units
});

export const addEditUnitFail = () => ({
  type: UNITS.ADD_EDIT.FAIL
});

export const setUnitsSnackBarAsHidden = () => ({
  type: UNITS.SNACK_BAR.HIDE
});

export const toggleDeleteUnitModal = showModal => ({
  type: UNITS.MODAL.TOGGLE_DELETE,
  showModal
});
