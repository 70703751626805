import { fork } from 'redux-saga/effects';

import country from './country';
import specialtiesSaga from './specialties';
import servicesSaga from './services';
import unitsSaga from './units';

const sagas = [servicesSaga, unitsSaga, country, specialtiesSaga];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
