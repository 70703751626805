import { combineReducers } from 'redux';

import country from './country';
import specialties from './specialties';
import services from './services';
import units from './units';
import users from './users'

export default combineReducers({
  services,
  units,
  country,
  specialties,
  users
});
