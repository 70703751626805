import { USERS } from '../actions/users';

const initialState = {
  role: '-1',
  roletest:'-1'
};

export default (state = initialState, { type, ...payload }) => {

  switch (type) {
    case USERS.SETUSERROLE: {
      const {role} =payload;
      return {
        ...state,
        role:payload.role,
        roletest:role,
      };
    }

    default:
      return state;
  }
};
