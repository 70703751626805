import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DownCarretIcon from '../../assets/down-carret.svg';
import {
  EnvelopContainer,
  OptionListContainer,
  Item,
  OptionIcon,
  OptionLabel,
  DownCarret,
} from './UserEnvelop.style';

const UserEnvelop = ({ options }) => {
  const [isOpened, toggleEnvelop] = useState(false);

  useEffect(() => {
    const myElementToCheckIfClicksAreInsideOf = document.querySelector('#user-envelop-components');
    if (myElementToCheckIfClicksAreInsideOf) {
      document.body.addEventListener('click', event => {
        if (!myElementToCheckIfClicksAreInsideOf.contains(event.target)) {
          toggleEnvelop(false);
        }
      });
    }
  }, []);

  return (
    <EnvelopContainer id="user-envelop-components">
      <DownCarret src={DownCarretIcon} alt="DownCarretIcon" onClick={() => toggleEnvelop(!isOpened)}/>
      {isOpened && (
        <OptionListContainer>
          {options.map(option => (
            <Item key={option.optionLabel} onClick={() => option.optionAction()}>
              <OptionIcon src={option.icon} alt={option.optionLabel} />
              <OptionLabel>{option.optionLabel}</OptionLabel>
            </Item>
          ))}
        </OptionListContainer>
      )}
    </EnvelopContainer>
  );
};

UserEnvelop.propTypes = {
  options: PropTypes.array,
};

export default UserEnvelop;