import { UNITS } from '../actions/units';

const initialState = {
  units: {
    list: [],
    filteredList: [],
    state: undefined,
    loaded: false
  },
  fetchDetails: {
    unitDetails: { unitCountryDetails: [] },
    unitKey: undefined,
    state: undefined
  },
  add_edit: {
    list: [],
    state: undefined
  },
  snackBar: { show: false, message: '' },
  deleteUnitModal: {
    show: false,
    unitDetails: { unitCountryDetails: [] }
  }
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case UNITS.FETCH.LOAD:
      return {
        ...state,
        units: {
          list: [],
          filteredList: [],
          state: UNITS.FETCH.LOAD,
          loaded: false
        }
      };

    case UNITS.FETCH.SUCCESS: {
      return {
        ...state,
        units: {
          list: payload.units,
          filteredList: payload.units,
          state: UNITS.FETCH.SUCCESS,
          loaded: true
        }
      };
    }

    case UNITS.FETCH.FAIL:
      return {
        ...state,
        units: {
          list: [],
          filteredList: [],
          state: UNITS.FETCH.FAIL,
          loaded: false
        }
      };

    case UNITS.FETCH_DETAILS.LOAD:
      return {
        ...state,
        fetchDetails: {
          unitDetails: { unitCountryDetails: [] },
          unitKey: payload.unitKey,
          state: UNITS.FETCH_DETAILS.LOAD
        }
      };

    case UNITS.FETCH_DETAILS.SUCCESS: {
      return {
        ...state,
        fetchDetails: {
          ...state.fetchDetails,
          unitDetails: payload.unitDetails,
          state: UNITS.FETCH_DETAILS.SUCCESS
        }
      };
    }

    case UNITS.FETCH_DETAILS.FAIL:
      return {
        ...state,
        fetchDetails: {
          ...state.fetchDetails,
          unitDetails: { unitCountryDetails: [] },
          state: UNITS.FETCH_DETAILS.FAIL
        }
      };

    case UNITS.ADD_EDIT.LOAD:
      return {
        ...state,
        add_edit: {
          list: [],
          filteredList: [],
          state: UNITS.ADD_EDIT.LOAD
        }
      };

    case UNITS.ADD_EDIT.SUCCESS: {
      return {
        ...state,
        add_edit: {
          list: payload.units,
          filteredList: payload.units,
          state: UNITS.ADD_EDIT.SUCCESS
        },
        snackBar: { show: true, message: 'Units Saved successfully' }
      };
    }

    case UNITS.ADD_EDIT.FAIL:
      return {
        ...state,
        add_edit: {
          list: [],
          filteredList: [],
          state: UNITS.ADD_EDIT.FAIL
        }
      };

    case UNITS.MODAL.TOGGLE_DELETE: {
      return Object.assign({}, state, {
        deleteUnitModal: { show: payload.showModal, unitDetails: { unitCountryDetails: [] } }
      });
    }

    default:
      return state;
  }
};
